<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-form ref="wechat-send" label-width="100px" :rules="rules" :model="formData" >
          <el-form-item label="发信微信" prop="wxid">
            <el-select v-model="formData.wxid" @change="eventWechatChange">
              <el-option
                  v-for="(item, index) in relate.wechat"
                  :key="index"
                  :label="item.nickname"
                  :value="item.wxid" />
            </el-select>
          </el-form-item>

          <el-form-item label="发送的群" prop="group_id">
            <el-select
                filterable
                v-model="formData.group_id"
                placeholder="请选择发送的群">
              <el-option
                  v-for="(item, index) in relate.groups"
                  :key="index"
                  :label="item.nickname"
                  :value="item.wxid" />
            </el-select>
          </el-form-item>

          <el-form-item label="发送内容" prop="content">
            <el-input
                type="textarea"
                :row="5"
                placeholder="需要发送的内容"
                v-model="formData.content"/>
          </el-form-item>

          <el-button type="success" @click="onSubmit">发送</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiGetWxGroups, apiGetWx, apiWxSend} from '@/api/wechatApi'
export default {
    data () {
        return {
          formData: {
            wxid: '',
            content: '',
            group_id: '',
          },
          rules:{
            wxid: [{required: true, message: '轻选择发信微信', trigger: 'blur',}],
            group_id: [{required: true, message: '请选择关联的群', trigger: 'blur',}],
            content: [{required: true, message: '亲输入发送内容', trigger: 'blur',}],
          },
          relate: {
            wechat: [],
            groups: [],
            peoples: [],
            wechat_proxy: {}
          },
        }
    },
    created() {
      this.getWx()
    },
    methods: {
      // 获取微信
      getWx() {
        apiGetWx().then(data => {
          this.relate.wechat = data
          data.forEach(val => {
            this.relate.wechat_proxy[val.wxid] = val.nickname
          })
        })
      },
      // 获取群
      getGroups(query = {}) {
        if (this.relate.groups.length) {
          return
        }

        apiGetWxGroups(query).then(data => {
          this.relate.groups = data
        })
      },
      // 微信改变
      eventWechatChange() {
        this.getGroups({wxid: this.formData.wxid})
      },
      onSubmit() {
        this.$refs['wechat-send'].validate(valid => {
          if (valid) {
            apiWxSend(this.formData).then(() => {
              this.$message.success('发送成功!')
              this.formData = {
                wxid: '',
                content: '',
                group_id: '',
              }
            })
          }
        })

      }
    }
}
</script>
